<template>
  <v-card>
    <v-card-title>
      <span v-if="type == 'update'" class="headline">Account bearbeiten</span>
      <span v-else class="headline">Account registrieren</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="userForm" v-model="newUser" lazy-validation>
          <v-row>
            <v-col class="d-flex" cols="12" lg="3">
              <v-select
                :items="genders"
                ref="anrede"
                v-model="newUserData.gender"
                :rules="[() => !!newUserData.gender || 'Dieses Feld wird benötigt!']"
                label="Anrede"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" lg="9">
              <v-text-field
                ref="name"
                v-model="newUserData.fullName"
                :rules="[() => !!newUserData.fullName || 'Dieses Feld wird benötigt!']"
                label="Vor- und Nachname"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12">
              <v-text-field
                ref="mail"
                v-model="newUserData.mail"
                :rules="[() => !!newUserData.mail || 'Dieses Feld wird benötigt!']"
                required
                label="E-Mail Adresse"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-text-field
                ref="telefonnummer"
                v-model="newUserData.phoneNumber"
                :rules="[() => !!newUserData.phoneNumber || 'Dieses Feld wird benötigt!']"
                label="Telefonnummer"
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6">
              <v-text-field
                ref="identitynumber"
                v-model="newUserData.identityNumber"
                label="Ausweisnummer"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="newUserData.birthdayDay" label="Tag" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="newUserData.birthdayMonth" label="Monat" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="newUserData.birthdayYear" required label="Jahr"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="8">
              <v-text-field v-model="newUserData.streetName" required label="Straße"> </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="newUserData.houseNumber" required label="Hausnummer"> </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="newUserData.zipCode" required label="Postleitzahl"> </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="newUserData.cityName" required label="Stadt"> </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="6">
              <v-text-field
                v-if="type == 'update'"
                v-model="newUserData.password"
                label="Passwort"
                hint="Mindestens 8 Zeichen"
                counter
                required
              ></v-text-field>
              <v-text-field
                v-else
                v-model="newUserData.password"
                :rules="[() => newUserData.password.length >= 8 || 'Mindestens 8 Zeichen']"
                label="Passwort"
                hint="Mindestens 8 Zeichen"
                counter
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="6">
              <v-text-field
                v-if="type == 'update'"
                v-model="newUserData.password2"
                :rules="[() => newUserData.password2 == newUserData.password || 'Passwörter müssen übereinstimmen']"
                hint="Mindestens 8 Zeichen"
                counter
                label="Passwort bestätigen"
                required
              ></v-text-field>
              <v-text-field
                v-else
                v-model="newUserData.password2"
                :rules="[
                  () => newUserData.password2.length >= 8 || 'Mindestens 8 Zeichen',
                  () => newUserData.password2 == newUserData.password || 'Passwörter müssen übereinstimmen',
                ]"
                hint="Mindestens 8 Zeichen"
                counter
                label="Passwort bestätigen"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="type == 'update'" color="primary" @click="updateUser()">
        Speichern
      </v-btn>
      <v-btn v-else color="blue darken-1" text @click="createUser()">
        Registrieren
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const dayjs = require("dayjs");

export default {
  props: {
    user: Object,
    type: String,
  },
  data() {
    return {
      genders: [
        { text: "Herr", value: "m" },
        { text: "Frau", value: "f" },
        { text: "Divers", value: "d" },
      ],
      newUserData: {
        houseNumber: "",
        zipCode: 0,
        streeName: "",
        cityname: "",
        identityNumber: "",
        birthdayDay: 1,
        birthdayMonth: 1,
        birthdayYear: 1970,
        password: "",
        password2: "",
      },
      newUser: false,
      snackbar: false,
      snackbarText: "",
    };
  },
  mounted() {
    if (this.type == "update" && this.user) {
      let birthday = dayjs(this.user.birthday);

      this.newUserData.mail = this.user.emailAddress;
      this.newUserData.fullName = this.user.fullName;
      this.newUserData.phoneNumber = this.user.phoneNumber;
      this.newUserData.gender = this.user.gender;
      this.newUserData.streetName = this.user.streetName;
      this.newUserData.cityName = this.user.cityName;
      this.newUserData.houseNumber = this.user.houseNumber;
      this.newUserData.zipCode = this.user.zipCode;
      this.newUserData.identityNumber = this.user.identityNumber;
      this.newUserData.birthdayDay = birthday.date();
      this.newUserData.birthdayMonth = birthday.month() + 1;
      this.newUserData.birthdayYear = birthday.year();
    }
  },
  methods: {
    updateUser() {
      if (this.$refs.userForm.validate()) {
        var birthday = new Date(
          this.newUserData.birthdayYear,
          this.newUserData.birthdayMonth - 1,
          this.newUserData.birthdayDay
        );

        this.$http({
          method: "put",
          url: "/account/update-profile",
          data: {
            emailAddress: this.newUserData.mail,
            fullName: this.newUserData.fullName,
            phoneNumber: this.newUserData.phoneNumber,
            gender: this.newUserData.gender,
            streetName: this.newUserData.streetName,
            cityName: this.newUserData.cityName,
            houseNumber: this.newUserData.houseNumber,
            zipCode: this.newUserData.zipCode,
            identityNumber: this.newUserData.identityNumber,
            birthday: birthday.getTime(),
          },
        })
          .then((res) => {
            if (this.newUserData.password != "") {
              this.$http({
                method: "put",
                url: "/account/update-password",
                data: {
                  password: this.newUserData.password,
                },
              })
                .then(() => {
                  this.$store.dispatch("logout").then(() => {
                    this.$router.push("/login");
                  });
                })
                .catch((err) => {
                  console.log("AXIOS ERROR: ", err);
                  this.snackbarText = "Es ist ein Fehler bei der Abfrage aufgetreten";
                  this.snackbar = true;
                });
            }

            this.$emit("updateuser", res.data);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            if (err.response) {
              if (err.response.status == 409) {
                this.snackbarText = "Die eingebene Mailadresse ist vergeben!";
                this.snackbar = true;
              }
            } else {
              this.snackbarText = "Es ist ein Fehler bei der Abfrage aufgetreten";
              this.snackbar = true;
            }
          });
      }
    },
    createUser() {
      if (this.$refs.userForm.validate()) {
        var birthday = new Date(
          this.newUserData.birthdayYear,
          this.newUserData.birthdayMonth - 1,
          this.newUserData.birthdayDay
        );

        this.$http({
          method: "post",
          url: "/entrance/signup",
          data: {
            emailAddress: this.newUserData.mail,
            password: this.newUserData.password,
            fullName: this.newUserData.fullName,
            phoneNumber: this.newUserData.phoneNumber,
            gender: this.newUserData.gender,
            streetName: this.newUserData.streetName,
            cityName: this.newUserData.cityName,
            houseNumber: this.newUserData.houseNumber,
            zipCode: this.newUserData.zipCode,
            identityNumber: this.newUserData.identityNumber,
            birthday: birthday.getTime(),
          },
        })
          .then((res) => {
            this.$emit("newuser", res.data);
            this.$refs.userForm.reset();
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            if (err.response) {
              if (err.response.status == 409) {
                this.snackbarText = "Die eingebene Mailadresse ist vergeben!";
                this.snackbar = true;
              }
            } else {
              this.snackbarText = "Es ist ein Fehler bei der Abfrage aufgetreten";
              this.snackbar = true;
            }
          });
      }
    },
  },
};
</script>
